import React from 'react'
import Marquee from "react-fast-marquee";

export default function Section1() {
  return ( 
    <div className=' bg-center bg-no-repeat bg-cover overflow-hidden' style={{backgroundImage: "url('/assets/Bg/Bg.svg')"}}>
    <div className='flex flex-col lg:flex-row justify-center gap-10 items-start pt-20'> 
       <div className='lg:w-[50%]'>
          <p className='text-white font-marcellus uppercase text-3xl text-center'>"Visionary Entrepreneur, Leadership Mentor, Inspirational Speaker" </p>
       </div>
       <div className='lg:w-[50%] flex justify-center lg:justify-start z-10' >
        <img className='w-6/12' src='/assets/Landing/Hero banner_KP 2.svg' ></img>
    </div>
    </div>
    <div className='flex flex-col gap-10 lg:gap-20 absolute lg:top-[50%] top-[65%]'>
    <div>
    <Marquee direction="right" speed={280}>
     <div className='flex lg:gap-20 gap-5 px-10' >
     <img className='w-2/12' src='/assets/Landing/KALPESH PATEL.svg'></img>
     <img className='w-2/12' src='/assets/Landing/KALPESH PATEL (1).svg'></img>
     <img className='w-2/12' src='/assets/Landing/KALPESH PATEL.svg'></img>

     </div> 
  </Marquee>
  </div>
  <div className='z-50'>
  <Marquee direction="left" speed={280}>
  <div className='flex lg:gap-20 gap-5 px-6' >
  <img className='w-2/12' src='/assets/Landing/KALPESH PATEL (1).svg'></img>
  <img className='w-2/12' src='/assets/Landing/KALPESH PATEL.svg'></img>
  <img className='w-2/12' src='/assets/Landing/KALPESH PATEL (1).svg'></img>

  </div> 
</Marquee>
</div>
    </div>
    </div>
  )
}
