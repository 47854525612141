import { useEffect,useState } from 'react';
import AOS from 'aos';
export default function Section5() {
    useEffect(() => {
        AOS.init({
            duration: 1000, 
            once: false,    
        });
    }, []);
    const countries = [
        "India",
        "United States",
        "Canada",
        "Australia",
        "United Kingdom",
        "Germany",
        "France",
      ];
      const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        additionalInfo: "",
        country: "",
      });
    
      const [errors, setErrors] = useState({});
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: "" });
      };
    
      const validate = () => {
        const newErrors = {};
    
        if (!formData.firstName.trim()) {
          newErrors.firstName = "First Name is required";
        }
        if (!formData.lastName.trim()) {
          newErrors.lastName = "Last Name is required";
        }
        if (!formData.email.trim()) {
          newErrors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
          newErrors.email = "Invalid email address";
        }
        if (!formData.phone.trim()) {
          newErrors.phone = "Phone number is required";
        } else if (!/^\d{10}$/.test(formData.phone)) {
          newErrors.phone = "Invalid phone number";
        }
        if (!formData.additionalInfo.trim()) {
          newErrors.additionalInfo = "Additional information is required";
        }
        if (!formData.country) {
          newErrors.country = "Country is required";
        }
    
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
          console.log("Form submitted successfully", formData);
        } else {
          console.log("Validation errors", errors);
        }
      };
    
      const [videoFile, setVideoFile] = useState(null);
      const [imageFile, setImageFile] = useState(null);
    
      const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
          if (file.size > 10 * 1024 * 1024) {
            alert("File size must be less than 10MB");
            return;
          }
          setVideoFile(URL.createObjectURL(file));
        }
      };
    
      const handleCancel = () => {
        setVideoFile(null);
      };
    
      const handleFileChange2 = (e) => {
        const file = e.target.files[0];
        if (file) {
          if (file.size > 10 * 1024 * 1024) {
            alert("File size must be less than 10MB");
            return;
          }
          setImageFile(URL.createObjectURL(file)); 
        }
      };
    
      const handleCancel2 = () => {
        setImageFile(null);
      };
    
      const [isopenpopup, setIspopupopen] = useState(false);
    
      const handlepopup = () => {
        setIspopupopen(!isopenpopup);
      };
    
      
    return (
        <div className='overflow-hidden'>
            <div className='min-h-screen grid content-center bg-gradient-to-b from-[#FFBA6E] to-[#FF6061] pb-5'>
                <div>
                    <div className='flex justify-center gap-5 text-3xl md:text-4xl lg:text-6xl 2xl:text-7xl uppercase text-white font-medium font-marcellus pt-10 lg:pt-0' style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>
                        <h1 data-aos='fade-right' data-aos-duration='1500'>Collaborate </h1>
                        <h1 data-aos='fade-left' data-aos-duration='1500'>With Me</h1>
                    </div>
                    <div className='mt-10 flex flex-col lg:flex-row justify-around items-center w-[90%] mx-auto'>
                        <div className='w-[100%] lg:w-[50%]'>
                            <div className='bg-white/50 shadow-md text-center  p-10 2xl:p-20 rounded-2xl'>
                                <h1 className='font-bold text-3xl font-roboto'  data-aos='fade-right' data-aos-duration='1500'>Speaking Engagements </h1>
                                <p className='text-xl mt-5 leading-8 font-roboto'  data-aos='fade-up' data-aos-duration='2000'>I love connecting with audiences to inspire and drive action. If you need a speaker who can motivate and engage, I’m here to make your event memorable. </p>
                            </div>
                        </div>
                        <div className='w-[100%] lg:w-[30%]'>
                            <div  data-aos='fade-up' data-aos-duration='2000'><img src='/assets/Landing/AllKP.svg'></img></div>
                        </div>
                    </div>
                    <div className='mt-5 flex justify-center'>
                        <button  onClick={handlepopup} className='px-5 py-2 rounded-md bg-white font-medium shadow-md'  data-aos='fade-up' data-aos-duration='2250'>LET’S WORK TOGETHER</button>
                    </div>
                </div>
            </div>
            {isopenpopup && (
                <div className="w-full fixed inset-0 flex justify-center items-center bg-black/50 z-50">
                  <div className=" bg-[#EAEAEA] p-10 ">
                    <div>
                      <button
                        className="w-full text-right text-black rounded-md font-semibold "
                        onClick={handlepopup}
                      >
                        x
                      </button>
                    </div>
                   
        
                    <form onSubmit={handleSubmit} className="mt-10">
                      <div className="flex md:flex-row flex-col  gap-5">
                        <div className="md:w-1/2 w-full">
                          <input
                            type="text"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                            className="placeholder:text-black w-full border border-black bg-transparent rounded-md py-3 px-4"
                            placeholder="First Name"
                          />
                          {errors.firstName && (
                            <p className="text-red-500 text-sm">{errors.firstName}</p>
                          )}
                        </div>
                        <div className="md:w-1/2 w-full">
                          <input
                            type="text"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                            className="placeholder:text-black w-full border border-black bg-transparent rounded-md py-3 px-4"
                            placeholder="Last Name"
                          />
                          {errors.lastName && (
                            <p className="text-red-500 text-sm">{errors.lastName}</p>
                          )}
                        </div>
                      </div>
        
                      <div className="flex md:flex-row flex-col  gap-5 mt-5">
                        <div className="md:w-1/2 w-full">
                          <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="placeholder:text-black w-full border border-black bg-transparent rounded-md py-3 px-4"
                            placeholder="Email"
                          />
                          {errors.email && (
                            <p className="text-red-500 text-sm">{errors.email}</p>
                          )}
                        </div>
                        <div className="md:w-1/2 w-full">
                          <input
                            type="text"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            className="placeholder:text-black w-full border border-black bg-transparent rounded-md py-3 px-4"
                            placeholder="Phone Number"
                          />
                          {errors.phone && (
                            <p className="text-red-500 text-sm">{errors.phone}</p>
                          )}
                        </div>
                      </div>
        
                     
        
                      <div className="mt-5">
                        <textarea
                          name="additionalInfo"
                          value={formData.additionalInfo}
                          onChange={handleChange}
                          className="w-full placeholder:text-black border border-black bg-transparent rounded-md py-3 px-4"
                          placeholder="Additional Information"
                        ></textarea>
                        {errors.additionalInfo && (
                          <p className="text-red-500 text-sm">
                            {errors.additionalInfo}
                          </p>
                        )}
                      </div>
        
                      <div className="flex justify-center mt-5">
                        <button
                          type="submit"
                          className="px-14 font-semibold py-2.5 shadow-md shadow-black/25 bg-gradient-to-r from-[#F2534F] to-[#FA8F3C] rounded-xl text-sm text-white text-center"
                        >
                          SUBMIT
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              )}
        </div>
    )
}
