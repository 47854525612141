import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Landing from '../Components/Pages/Home/Landing';
import Header from '../Components/Layout/Header'
const Routers = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />} />

       




      </Routes>
    </BrowserRouter>
  );
};

export default Routers;
