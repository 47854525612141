import { faLocationDot, faLocationPin, faMagnifyingGlass, faShoppingCart, faTruck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'

export default function Header() {
    const [isDropdown, setDropdown] = useState(false);
    const dropdownRef = useRef(null);
    const buttonRef = useRef(null);

    const handleClickOutside = (event) => {
        if (
            (isDropdown && dropdownRef.current && !dropdownRef.current.contains(event.target) && buttonRef.current && !buttonRef.current.contains(event.target))
        ) {
            setDropdown(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isDropdown]);

    const handleDropdown = () => {
        setDropdown(!isDropdown)
    }
    return (
        <div className=''>
           
            <div className='flex justify-between items-center w-[90%] bg-white px-5 py-5'>
                <div className='lg:w-[30%]'>
                    <img src="/assets/Logo/Logo.svg"></img>
                </div>
                <div className='hidden lg:block w-[70%]'>
                    <div className='flex gap-12 items-center justify-start font-medium justify-end'>
                        <a className='font-roman font-bold' href='/'>HOME</a>
                        <a className='font-roman font-bold' href='/aboutus'>ABOUT KALPESH</a>
                        <a className='font-roman font-bold' href='/products'>COLLABORATE</a>
                        <a className='font-roman font-bold' href='/comingsoon'>BLOG</a>
                        <a className='font-roman font-bold' href='/comingsoon'>CONTACT</a>
                       
                    </div>
                </div>
                <div className='block lg:hidden'>
                    <div className='flex items-center justify-end gap-3'>
                        <button className="" onClick={handleDropdown} ref={buttonRef}>
                            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            {isDropdown && (
                <div className="absolute right-3 z-30" data-aos="flip-right" data-aos-duration='1000' ref={dropdownRef}>
                    <nav className='flex flex-col gap-1.5  text-center  border shadow-md rounded-xl p-3 font-semibold bg-white'>
                    <a className='font-roman font-bold' href='/'>HOME</a>
                    <a className='font-roman font-bold' href='/aboutus'>ABOUT KALPESH</a>
                    <a className='font-roman font-bold' href='/products'>COLLABORATE</a>
                    <a className='font-roman font-bold' href='/comingsoon'>BLOG</a>
                    <a className='font-roman font-bold' href='/comingsoon'>CONTACT</a>
                    </nav>
                </div>
            )}
        </div>
    )
}
