import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Section6 = () => {
  const countries = [
    "India",
    "United States",
    "Canada",
    "Australia",
    "United Kingdom",
    "Germany",
    "France",
  ];
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    additionalInfo: "",
    country: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const validate = () => {
    const newErrors = {};

    if (!formData.firstName.trim()) {
      newErrors.firstName = "First Name is required";
    }
    if (!formData.lastName.trim()) {
      newErrors.lastName = "Last Name is required";
    }
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Invalid email address";
    }
    if (!formData.phone.trim()) {
      newErrors.phone = "Phone number is required";
    } else if (!/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = "Invalid phone number";
    }
    if (!formData.additionalInfo.trim()) {
      newErrors.additionalInfo = "Additional information is required";
    }
    if (!formData.country) {
      newErrors.country = "Country is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      console.log("Form submitted successfully", formData);
    } else {
      console.log("Validation errors", errors);
    }
  };

  const [videoFile, setVideoFile] = useState(null);
  const [imageFile, setImageFile] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 10 * 1024 * 1024) {
        alert("File size must be less than 10MB");
        return;
      }
      setVideoFile(URL.createObjectURL(file));
    }
  };

  const handleCancel = () => {
    setVideoFile(null);
  };

  const handleFileChange2 = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 10 * 1024 * 1024) {
        alert("File size must be less than 10MB");
        return;
      }
      setImageFile(URL.createObjectURL(file)); 
    }
  };

  const handleCancel2 = () => {
    setImageFile(null);
  };

  const [isopenpopup, setIspopupopen] = useState(false);

  const handlepopup = () => {
    setIspopupopen(!isopenpopup);
  };

  

  const settings = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 2,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "60px",
    autoplay: true,
    autoplaySpeed: 1,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          centerPadding: "20px",
        },
      },
    ],
  };

  return (
    <div className="w-full bg-[#FCF7F7] min-h-screen flex flex-col justify-center items-center font-roboto pt-5">
      <div className="w-[85%] mx-auto flex flex-col justify-center items-center gap-10 md:gap-0">
        <h1 className="font-marcellus text-4xl lg:text-7xl font-medium ">
          Testimonials
        </h1>
      </div>
      <div className="w-full mt-16">
        <Slider {...settings} className=" w-[100%]">
          <div className=" md:px-5 px-2 h-full ">
            <div className=" bg-[#FFBA6EED] rounded-lg md:h-[18rem] ">
              <div className="h-full rounded-lg px-3 pt-10 flex md:flex-row flex-col items-center gap-10 ">
                <div className=" sm:w-[40%] relative h-full">
                  <img src="/assets/Landing/user7.svg" className="h-full" />
                  <img
                    src="/assets/Landing/quotes1.svg"
                    className=" absolute -top-5 -right-3 "
                  />
                </div>
                <div className=" sm:w-[60%] ">
                  <p className="text-xs leading-7">
                    "Hi Kalpesh! Thank you so much for the genuine leadership
                    spirit in you... I was so touched with your humility despite
                    your success in your chosen field. Thank you also for the
                    free CD which you personally signed.{" "}
                  </p>
                  <p
                    style={{ webkitTextStroke: "1px black" }}
                    className="text-xl my-5 text-white font-bold"
                  >
                    Roy Codia
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className=" md:px-5 px-2 h-full ">
            <div className=" bg-gradient-to-b from-[#FF6061C7] to-[#F98517C7] rounded-lg md:h-[18rem] ">
              <div className="h-full rounded-lg px-3 pt-10 flex md:flex-row flex-col items-center gap-10 ">
                <div className=" sm:w-[40%] relative h-full">
                  <img src="/assets/Landing/user6.svg" className="h-full" />
                  <img
                    src="/assets/Landing/quotes2.svg"
                    className=" absolute -top-5 -right-3 "
                  />
                </div>
                <div className=" sm:w-[60%] ">
                  <p className="text-xs leading-7">
                    "Hi Kalpesh! Thank you so much for the genuine leadership
                    spirit in you... I was so touched with your humility despite
                    your success in your chosen field. Thank you also for the
                    free CD which you personally signed.{" "}
                  </p>
                  <p
                    style={{ webkitTextStroke: "1px black" }}
                    className="text-xl my-5 text-white font-bold"
                  >
                    Roy Codia
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className=" md:px-5 px-2 h-full ">
            <div className=" bg-[#FF6061] rounded-lg md:h-[18rem] h-full  ">
              <div className="h-full rounded-lg px-3 pt-10 flex md:flex-row flex-col items-center gap-10 ">
                <div className=" sm:w-[40%] relative h-full">
                  <div className=" bg-black p-2 rounded-lg ">
                    <img src="/assets/Landing/user5.svg" className="h-full" />
                  </div>
                </div>
                <div className=" sm:w-[60%] ">
                  <p className="text-xs leading-7">
                    "Hi Kalpesh! Thank you so much for the genuine leadership
                    spirit in you... I was so touched with your humility despite
                    your success in your chosen field. Thank you also for the
                    free CD which you personally signed.{" "}
                  </p>
                  <p
                    style={{ webkitTextStroke: "1px black" }}
                    className="text-xl my-5 text-white font-bold"
                  >
                    Roy Codia
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
      <div className=" flex justify-center mt-10 ">
        <button
          onClick={handlepopup}
          className=" px-10 font-semibold py-3 shadow-md shadow-black/25 bg-gradient-to-r from-[#F2534F] to-[#FA8F3C] rounded-lg text-sm text-white text-center "
        >
          Click Here To Submit Yours
        </button>
      </div>

      {isopenpopup && (
        <div className="w-full fixed inset-0 flex justify-center items-center bg-black/50 z-50 px-5 ">
        <div className="h-[600px] sm:absolute top-10 lg:top-3 px-5 overflow-auto lg:overflow-hidden">
          <div className=" bg-[#EAEAEA] p-10 ">
            <div>
              <button
                className="w-full text-right text-black rounded-md font-semibold "
                onClick={handlepopup}
              >
                x
              </button>
            </div>
            <div className="flex items-center gap-5">
              <label className="cursor-pointer relative">
                {imageFile ? (
                  <div>
                    <button
                      className="w-full text-left text-black rounded-md font-semibold "
                      onClick={handleCancel2}
                    >
                      x
                    </button>
                    <img
                      src={imageFile}
                      alt="Uploaded Preview"
                      className="rounded-full w-24"
                    />
                  </div>
                ) : (
                  <img
                    src="/assets/Landing/user8.png"
                    className="rounded-full w-24"
                    alt="Default Profile"
                  />
                )}
                <input
                  type="file"
                  accept="image/*"
                  className="hidden"
                  onChange={handleFileChange2}
                />
              </label>
              <div>
                <p className="text-lg font-bold">Upload Image</p>
                <p className="text-sm">
                  File smaller than 10 MB at least 400px by 400px
                </p>
              </div>
            </div>

            <form onSubmit={handleSubmit} className="mt-10">
              <div className="flex md:flex-row flex-col  gap-5">
                <div className="md:w-1/2 w-full">
                  <input
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    className="placeholder:text-black w-full border border-black bg-transparent rounded-md py-3 px-4"
                    placeholder="First Name"
                  />
                  {errors.firstName && (
                    <p className="text-red-500 text-sm">{errors.firstName}</p>
                  )}
                </div>
                <div className="md:w-1/2 w-full">
                  <input
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    className="placeholder:text-black w-full border border-black bg-transparent rounded-md py-3 px-4"
                    placeholder="Last Name"
                  />
                  {errors.lastName && (
                    <p className="text-red-500 text-sm">{errors.lastName}</p>
                  )}
                </div>
              </div>

              <div className="flex md:flex-row flex-col  gap-5 mt-5">
                <div className="md:w-1/2 w-full">
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="placeholder:text-black w-full border border-black bg-transparent rounded-md py-3 px-4"
                    placeholder="Email"
                  />
                  {errors.email && (
                    <p className="text-red-500 text-sm">{errors.email}</p>
                  )}
                </div>
                <div className="md:w-1/2 w-full">
                  <input
                    type="text"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    className="placeholder:text-black w-full border border-black bg-transparent rounded-md py-3 px-4"
                    placeholder="Phone Number"
                  />
                  {errors.phone && (
                    <p className="text-red-500 text-sm">{errors.phone}</p>
                  )}
                </div>
              </div>

              <div className="flex md:flex-row flex-col  items-center gap-5 mt-5">
                <div className="md:w-1/2 w-full">
                  <select
                    name="country"
                    value={formData.country}
                    onChange={handleChange}
                    className="w-full placeholder:text-black border border-black bg-transparent rounded-md py-3 px-4"
                  >
                    <option value="">Select Country</option>
                    {countries.map((country) => (
                      <option key={country} value={country}>
                        {country}
                      </option>
                    ))}
                  </select>
                  {errors.country && (
                    <p className="text-red-500 text-sm">{errors.country}</p>
                  )}
                </div>
                <div className="md:w-1/2 w-full flex items-center gap-5">
                  <label className=" w-full cursor-pointer">
                    {videoFile ? (
                      <div>
                        <button
                          className=" text-black text-right w-full rounded-md font-semibold "
                          onClick={handleCancel}
                        >
                          x
                        </button>
                        <video
                          className="w-44 rounded"
                          controls
                          src={videoFile}
                          alt="Uploaded Video Preview"
                        />
                      </div>
                    ) : (
                      <div className=" flex justify-center items-center">
                        <div className="relative flex justify-center items-center">
                          <img
                            src="/assets/Landing/user8.png"
                            className="w-28"
                            alt="Profile"
                          />
                          <div className="absolute bg-black/40 rounded-full w-7 h-7 flex justify-center items-center">
                            <i
                              className="fa-solid fa-play fa-sm"
                              style={{ color: "#FFFFFF" }}
                            ></i>
                          </div>
                        </div>
                        <div>
                          <p className=" font-bold">
                            Upload Video <span>(optional)</span>
                          </p>
                          <p className="text-xs">
                            File smaller than 10 MB at least 400px by 400px
                          </p>
                        </div>
                      </div>
                    )}

                    <input
                      type="file"
                      accept="video/*"
                      className="hidden"
                      onChange={handleFileChange}
                    />
                  </label>
                </div>
              </div>

              <div className="mt-5">
                <textarea
                  name="additionalInfo"
                  value={formData.additionalInfo}
                  onChange={handleChange}
                  className="w-full placeholder:text-black border border-black bg-transparent rounded-md py-3 px-4"
                  placeholder="Additional Information"
                ></textarea>
                {errors.additionalInfo && (
                  <p className="text-red-500 text-sm">
                    {errors.additionalInfo}
                  </p>
                )}
              </div>

              <div className="flex justify-center mt-5">
                <button
                  type="submit"
                  className="px-14 font-semibold py-2.5 shadow-md shadow-black/25 bg-gradient-to-r from-[#F2534F] to-[#FA8F3C] rounded-xl text-sm text-white text-center"
                >
                  SUBMIT
                </button>
              </div>
            </form>
          </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Section6;
