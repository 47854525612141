import { useEffect, useState } from 'react';
import AOS from 'aos';
const Section8 = () => {
  useEffect(() => {
    AOS.init({
        duration: 1000, 
        once: false,    
    });
}, []);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const handleChange = (e) => {
    setEmail(e.target.value);
    setError("");
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email) {
      setError("Email is required.");
    } else if (!validateEmail(email)) {
      setError("Please enter a valid email address.");
    } else {
      setError("");
    }
  };

  return (
    <div className="w-full bg-gradient-to-b from-[#FFBA6E] to-[#FF6061] bg-cover flex justify-center items-center h-screen">
      <div className=" lg:w-[80%] mx-auto text-white ">
        <h1
          style={{ textShadow: " 2px 2px 2px #00000070 " }}
          className=" font-marcellus text-4xl lg:text-6xl font-medium text-center "
          data-aos="slide-left"
          data-aos-duration="1000"
        >
          Sign Up For Exclusive Insights
        </h1>

        <div className="flex md:flex-row flex-col justify-center items-center mt-10">
          <div className="lg:w-[60%] md:w-[50%]  hidden md:block mx-auto relative">
            <div className="relative py-10">
              <div
                style={{
                  borderRadius: "85% 15% 70% 30% / 50% 17% 83% 50%",
                }}
                className="bg-[#393939] absolute -bottom-24 z-0 bg-cover w-[65%] mx-auto h-52"
                data-aos="fade-up"
              ></div>

              <div
                style={{
                  borderRadius: "68% 32% 28% 72% / 47% 29% 71% 53%",
                }}
                className="bg-[#505B74] z-10 absolute -bottom-24 left-10 bg-cover w-[50%] mx-auto h-64"
                data-aos="fade-up"
              ></div>

              <img
                src="/assets/Landing/KPimg.svg"
                className="w-8/12 absolute z-20 -bottom-24 left-14 -mb-10"
                data-aos="fade-right"
              />
            </div>
          </div>

          <div className="lg:w-[40%] md:w-[50%]  px-5 font-roboto py-14 text-center">
            <div data-aos="fade-left"
            data-aos-duration="2000">
              <p className="text-xl">
                Join my newsletter to receive the latest updates on Blogs about
                leadership, business growth, and other exclusive content.
              </p>
            </div>
            <div className="mt-10">
              <input
                type="email"
                name="youremail"
                value={email}
                onChange={handleChange}
                placeholder="Enter your email here"
                className="w-full text-white focus:outline-none placeholder:text-white bg-transparent border-b border-white py-1 px-3"
                data-aos="fade-up"
              />
              {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
            </div>
            <div className="mt-10">
              <button
                onClick={handleSubmit}
                className="bg-white text-black px-16 py-2 rounded-lg uppercase font-bold text-center text-sm"
                data-aos="fade-up"
              >
                Stay Updated with Me
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section8;
