import { useEffect } from 'react';
import AOS from 'aos';
const Section7 = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000, 
            once: false,    
        });
    }, []);
    return (
        <div className='w-full bg-[#FCF7F7] min-h-screen flex justify-center items-center font-roboto ' >
            <div className='w-[85%] mx-auto flex md:flex-row flex-col justify-center items-center gap-10 md:gap-0' >
                <div className='md:w-[65%]' >
                    <h1 data-aos="fade-right" className=' font-marcellus text-4xl lg:text-6xl font-medium ' >BLOG</h1>
                    <div className='mt-14' data-aos="fade-right" >
                        <p className='md:w-[80%] text-xl md:text-3xl font-bold' >My Journey Through MLM and Direct Selling: Empowerment, Integrity, and Success — Kalpesh Batook Patel</p>
                        <p className='mt-5 md:w-[60%]' >Ever feel like the odds are stacked against you? I’m Kalpesh Patel, and my journey through Multi-Level Marketing (MLM) and Direct...</p>
                    </div>
                    <div className=' flex justify-start mt-10 ' data-aos="fade-right" >
                        <button className='uppercase px-10 font-semibold py-3 shadow-md shadow-black/25 bg-gradient-to-r from-[#F2534F] to-[#FA8F3C] rounded-lg text-sm text-white text-center ' >read more</button>
                    </div>
                </div>
                <div className=' md:w-[35%] ' >
                    <div className='relative flex justify-center items-center ' data-aos="fade-up">
                        <img src='/assets/Landing/KPimg2.svg' className=' scale-110 ' />
                        <div className='absolute mb-5 bg-black/40 rounded-full w-10 h-10 flex justify-center items-center'>
                            <i class="fa-solid fa-play" style={{ color: '#FFFFFF' }}></i>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Section7